
import { RegionStatus } from "@/types/RegionStatus";
import { Status } from "@/types/Status";
import { defineComponent, PropType } from "@vue/runtime-core";
import TimeSpan from "./views/TimeSpan.vue";

export default defineComponent({
  components: { TimeSpan },
  props: {
    regionStatuses: {
      type: Array as PropType<RegionStatus[]>,
      required: true,
    },
  },
  computed: {
    sortedRegionStatusesInAlert(): RegionStatus[] {
      return getSortedListByStatus(this.regionStatuses, Status.ALERT);
    },
    sortedRegionStatusesInSafe(): RegionStatus[] {
      return getSortedListByStatus(this.regionStatuses, Status.OK);
    },
  },
});

function getSortedListByStatus(
  regionStatuses: RegionStatus[],
  status: Status
): RegionStatus[] {
  return regionStatuses
    .filter((regionStatus) => regionStatus.status === status)
    .sort((a, b) => Number(b.date || 0) - Number(a.date || 0));
}
