import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.socialNetworkIconsData, (socialNetworkIcon) => {
      return (_openBlock(), _createBlock(_component_Icon, {
        key: socialNetworkIcon.icon,
        icon: socialNetworkIcon.icon,
        onClick: ($event: any) => ($options.navigate(socialNetworkIcon.url, socialNetworkIcon.params))
      }, null, 8, ["icon", "onClick"]))
    }), 128))
  ]))
}