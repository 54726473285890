import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b91a10c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["d"]
const _hoisted_3 = ["font-size"]
const _hoisted_4 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    class: _normalizeClass([_ctx.cssClass, { region: true }]),
    id: _ctx.region.id
  }, [
    _createElementVNode("path", {
      d: _ctx.region.d
    }, null, 8, _hoisted_2),
    (_ctx.region.title)
      ? (_openBlock(), _createElementBlock("text", {
          key: 0,
          "font-size": _ctx.region.fontSize
        }, [
          _createElementVNode("tspan", {
            x: _ctx.region.titleX || 0,
            y: _ctx.region.titleY || 0
          }, _toDisplayString(_ctx.region.title), 9, _hoisted_4)
        ], 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}