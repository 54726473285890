import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db715a28"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "legend"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeSpan = _resolveComponent("TimeSpan")!

  return (
      _ctx.sortedRegionStatusesInAlert.length === 0 &&
      _ctx.sortedRegionStatusesInSafe.length > 0
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t("message.country_info_safe_title")) + " ", 1),
        _createVNode(_component_TimeSpan, {
          date: _ctx.sortedRegionStatusesInSafe[0].date
        }, null, 8, ["date"])
      ]))
    : _createCommentVNode("", true)
}