
import { defineComponent } from "vue";
import CredentialsComponent from "./components/CredentialsComponent.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import HomeComponent from "./components/HomeComponent.vue";
import ShareButtons from "./components/ShareButtons.vue";

export default defineComponent({
  name: "App",
  components: {
    HomeComponent,
    CredentialsComponent,
    ThemeSwitcher,
    ShareButtons,
  },
});
