import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 1000 670",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = { id: "map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegionComponent = _resolveComponent("RegionComponent")!

  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.svgRegions, (region) => {
        return (_openBlock(), _createBlock(_component_RegionComponent, {
          key: region.id,
          region: region,
          cssClass: _ctx.getClass(region),
          onClick: ($event: any) => (_ctx.$emit('region-click', region))
        }, null, 8, ["region", "cssClass", "onClick"]))
      }), 128))
    ])
  ]))
}