
import { defineComponent } from "@vue/runtime-core";
import { getDatesDiff } from "../utils/date";

export default defineComponent({
  name: "TimeSpan",
  props: {
    date: {
      type: Date,
      required: false,
    },
  },
  computed: {
    dateDiff() {
      return getDatesDiff(this.date || new Date(), new Date());
    },
  },
});
