export const RegionsMap: Record<string, string> = {
  м_Київ: "kyiv-city",
  Київська_область: "kyiv",
  Дніпропетровська_область: "dnipropetrovsk",
  Харківська_область: "kharkiv",
  Донецька_область: "donetsk",
  Запорізька_область: "zaporizhzhia",
  Чернігівська_область: "chernihiv",
  Полтавська_область: "poltava",
  Сумська_область: "sumy",
  Львівська_область: "lviv",
  Одеська_область: "odesa",
  Житомирська_область: "zhytomyr",
  Херсонська_область: "kherson",
  Луганська_область: "luhansk",
  Вінницька_область: "vinnytsia",
  Миколаївська_область: "mykolaiv",
  Кіровоградська_область: "kirovohrad",
  Черкаська_область: "cherkasy",
  Хмельницька_область: "khmelnytskyi",
  Волинська_область: "volyn",
  Рівненська_область: "rivne",
  ІваноФранківська_область: "ivano-frankivsk",
  Тернопільська_область: "ternopil",
  Закарпатська_область: "zakarpattia",
  Чернівецька_область: "chernivtsi",
  АРК: "crimea",
  Севастополь: "sevastopol",
};
