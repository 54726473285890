
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  methods: {
    changeLang() {
      let locale = this.$i18n.locale;
      let locales = Object.values(this.$i18n.availableLocales);
      this.$i18n.locale =
        locales[(locales.indexOf(locale) + 1) % locales.length];
    },
  },
});
