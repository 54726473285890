
import { defineComponent } from "@vue/runtime-core";
import { Mode } from "./utils/region/TitleGenerator/Mode";

export default defineComponent({
  emits: {
    onTitleModeChanged: (mode: Mode) => {
      return mode;
    },
  },
  data() {
    return {
      modes: Object.values(Mode) as Mode[],
      currentMode: Mode.MINUTES,
    };
  },
  methods: {
    switchTitle() {
      this.currentMode =
        this.modes[
          (this.modes.indexOf(this.currentMode) + 1) % this.modes.length
        ];
      this.$emit("onTitleModeChanged", this.currentMode);
    },
  },
  computed: {
    currentModeName(): string {
      const mode = "message.titles_mode_" + this.currentMode;
      return this.$t(mode);
    },
  },
});
