
import { defineComponent, PropType } from "@vue/runtime-core";
import { Icon } from "@iconify/vue";

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

function getOSTheme() {
  return !localStorage.theme || localStorage.theme === Theme.DARK
    ? Theme.DARK
    : Theme.LIGHT;
}

export default defineComponent({
  components: { Icon },
  props: {
    theme: {
      type: String as PropType<Theme>,
      default: getOSTheme(),
    },
  },
  methods: {
    toggleTheme() {
      this.currentTheme =
        this.currentTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
      this.setTheme(this.currentTheme);
    },
    setTheme(theme: string) {
      const bodyClassesList =
        document.getElementsByTagName("body")[0].classList;
      bodyClassesList.remove(Theme.DARK);
      bodyClassesList.add(theme);
    },
  },
  data() {
    return {
      currentTheme: this.theme,
    };
  },
  mounted() {
    this.setTheme(getOSTheme());
  },
  watch: {
    currentTheme(newTheme: string) {
      localStorage.theme = newTheme;
    },
  },
});
