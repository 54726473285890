
import { RegionInfo } from "@/types/RegionInfo";
import { defineComponent } from "@vue/runtime-core";
import { getDatesDiff } from "@/components/utils/date";
import TimeSpan from "./views/TimeSpan.vue";

export default defineComponent({
  components: { TimeSpan },
  emits: ["close"],
  props: {
    regionInfo: {
      type: RegionInfo,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      if (!this.regionInfo?.regionStatus.date) {
        return "";
      }
      return getDatesDiff(this.regionInfo?.regionStatus.date, new Date());
    },
  },
});
