import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b547e296"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ukraine-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgUkraineMap = _resolveComponent("SvgUkraineMap")!
  const _component_CountryInfoComponent = _resolveComponent("CountryInfoComponent")!
  const _component_RegionInfoComponent = _resolveComponent("RegionInfoComponent")!
  const _component_CoundownComponent = _resolveComponent("CoundownComponent")!
  const _component_LocaleSwitcher = _resolveComponent("LocaleSwitcher")!
  const _component_TitleSwitcher = _resolveComponent("TitleSwitcher")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SvgUkraineMap, {
        regionStatuses: _ctx.regionStatuses,
        regionTitles: _ctx.regionTitles,
        onRegionClick: _ctx.getRegionInfo
      }, null, 8, ["regionStatuses", "regionTitles", "onRegionClick"]),
      _createVNode(_component_CountryInfoComponent, { regionStatuses: _ctx.regionStatuses }, null, 8, ["regionStatuses"])
    ]),
    (_ctx.selectedRegionInfo)
      ? (_openBlock(), _createBlock(_component_RegionInfoComponent, {
          key: 0,
          regionInfo: _ctx.selectedRegionInfo,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedRegionInfo = undefined))
        }, null, 8, ["regionInfo"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CoundownComponent, {
      onClick: _ctx.refreshRegions,
      onTimeOver: _ctx.refreshRegions
    }, null, 8, ["onClick", "onTimeOver"]),
    _createVNode(_component_LocaleSwitcher),
    _createVNode(_component_TitleSwitcher, { onOnTitleModeChanged: _ctx.onTitlesModeChanged }, null, 8, ["onOnTitleModeChanged"])
  ], 64))
}