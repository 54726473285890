
import { SvgRegion } from "@/types/SvgRegion";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    region: { type: Object as PropType<SvgRegion>, required: true },
    cssClass: { type: String, required: false },
  },
});
