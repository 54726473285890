import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6ef8bf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeSpan = _resolveComponent("TimeSpan")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t(
          "message.region_info_region_status_" + _ctx.regionInfo.regionStatus.status,
          {
            region: _ctx.$t("region." + _ctx.regionInfo.id),
          }
        )) + " ", 1),
      _createVNode(_component_TimeSpan, {
        date: _ctx.regionInfo.regionStatus.date
      }, null, 8, ["date"])
    ]),
    _createElementVNode("div", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, "×")
  ]))
}