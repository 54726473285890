
import { Icon } from "@iconify/vue";

export default {
  components: { Icon },
  data() {
    const socialNetworkIconsData: SocialNetworkIconData[] =
      getSocialNetworkIconsData();
    return {
      socialNetworkIconsData,
    };
  },
  methods: {
    navigate(socialUrl: string, paramsObject: Record<string, string>) {
      const params = new URLSearchParams(paramsObject).toString();
      const url = new URL(socialUrl);
      url.search = params;

      window.open(url.toString(), "_blank");
    },
  },
};

type SocialNetworkIconData = {
  icon: string;
  url: string;
  params: Record<string, string>;
};

function getSocialNetworkIconsData(): SocialNetworkIconData[] {
  return [
    {
      icon: "cib:twitter",
      url: "https://twitter.com/share",
      params: {
        url: document.location.href,
        text: document.title,
      },
    },
    {
      icon: "cib:facebook-f",
      url: "https://www.facebook.com/sharer/sharer.php",
      params: {
        u: document.location.href,
      },
    },
    {
      icon: "cib:whatsapp",
      url: "https://api.whatsapp.com/send",
      params: {
        text: document.location.href,
      },
    },
    {
      icon: "cib:viber",
      url: "viber://forward",
      params: {
        text: document.location.href,
      },
    },
    {
      icon: "cib:telegram-plane",
      url: "https://telegram.me/share/url",
      params: {
        url: document.location.href,
      },
    },
  ];
}
